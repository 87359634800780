@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.card-item-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  outline: 2px solid var(--cst-cards-item-block-outline);
  overflow: hidden;
  outline-offset: -2px;
  padding: 0px;
  border-radius: var(--cornerradius10);
  @include singleFixedSize(260px);

  @include min-1440-break {
    @include singleFixedSize(386px);
  }

  .item-rarity {
    position: absolute;
    z-index: -1;
    overflow: hidden;
  }

  .item {
    z-index: 1;
    pointer-events: none;

    @include singleFixedSize(152px);

    @include min-1440-break {
      @include singleFixedSize(218px);
    }
  }
}
